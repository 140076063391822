import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import EventCalendarUi from "../../ui/EventCalendar";
import { EventCalendar } from "../../lib/storyblok/types/blocks/EventCalendarBlock";
import { useTranslation } from "next-i18next";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import { useLinks } from "../../lib/storyblok/useLinks";
import Link from "next/link";
import Button from "../../ui/Button";
import { FormatDateRange } from "../../lib/utils/FormatDateRange";

const { Event } = EventCalendarUi;

const EventCalendar: StoryblokBlockComponent<EventCalendar> = ({
  title,
  preamble,
  buttons,
  events,
  eventsTotal,
  eventDisplay,
}) => {
  const { t } = useTranslation();
  const { globalLinks } = useViewmodel();
  const resolveLink = useLinks();
  const { locale } = useViewmodel();

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType || "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  if (!eventDisplay) {
    eventDisplay = "only-future-events";
  }

  return (
    <>
      here
      <EventCalendarUi
        title={title}
        preamble={preamble}
        buttons={buttonCollection}
        viewAllLabel={t("view-all")}
        viewAllLink={
          globalLinks.investorEventFilterPage &&
          FormatDateRange(globalLinks.investorEventFilterPage)
        }
        shouldShowViewAll={eventsTotal > 5}
      >
        {events &&
          events.map((evt) => {
            // We have to conditionally collect day, month and year as we have two different date format: "sv" format is d MMMM, yyyy and "default" format is MMMM d, yyyy
            const dateObject = FormatDateRange(evt.content.eventDate);
            const dateParts = dateObject.split(", ")[0].split(" ");
            const day = locale === "sv" ? dateParts[0] : dateParts[1];
            const month = locale === "sv" ? dateParts[1] : dateParts[0];
            const year = dateObject.split(", ")[1];
            return (
              <Event
                isPassed={new Date(evt.content.eventDate) < new Date()}
                key={evt.uuid}
                dateTime={evt.content.eventDate}
                day={day}
                month={month}
                year={year}
                title={evt.name}
                eventLink={resolveSlug(evt.full_slug)}
                description={evt.content.description}
                tags={evt.content.eventType}
                location={evt.content.eventLocation}
              />
            );
          })}
      </EventCalendarUi>
    </>
  );
};

export default EventCalendar;
