import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ShortFeaturesUi from "../../ui/ShortFeatures";
import { ShortFeaturesBlock } from "../../lib/storyblok/types/blocks/ShortFeaturesBlock";
import Button from "../../ui/Button";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import ImageVaultImage from "../ImageVaultImage";
import { useContentStyle, useButtonStyle } from "../../hooks/useStyle";
import { isValidImageData } from "../../lib/utils/imageUtils";

const ShortFeatures: StoryblokBlockComponent<ShortFeaturesBlock> = ({
  title,
  text,
  buttons,
  features,
  style,
  featureStyle,
  featureBoxed,
  spacing,
  outerSpacing,
  columns,
  contentAlignment,
  width,
  backgroundImage,
  backgroundOpacity = { value: 100 },
}) => {
  const resolveLink = useLinks();

  /* As we had different type definitions for features, we need to do some logic magic to make things work with generalized styles */
  const parentStyleInverted = useContentStyle(style);
  const featureStyleInverted = useContentStyle(featureStyle);
  const parentButtonStyle = useButtonStyle(style);
  const featureButtonStyle = useButtonStyle(featureStyle);

  const shouldUseParentStyle = (style) => {
    if (!style) return true;

    const normalizedStyle = style.startsWith("feature")
      ? style.substring(7).toLowerCase()
      : style.toLowerCase();

    return normalizedStyle === "none" || normalizedStyle === "";
  };

  const useParentStyle = shouldUseParentStyle(featureStyle);

  const isInverted = parentStyleInverted;
  const IsFeatureInverted = useParentStyle
    ? parentStyleInverted
    : featureStyleInverted;
  const buttonVariant = useParentStyle
    ? parentButtonStyle.buttonVariant
    : featureButtonStyle.buttonVariant;

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType || "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  return (
    <>
      <ShortFeaturesUi
        title={title}
        text={text}
        buttons={buttonCollection}
        style={style}
        spacing={spacing}
        outerSpacing={outerSpacing}
        columns={columns}
        contentAlignment={contentAlignment}
        width={width}
        inverted={isInverted}
        backgroundImage={
          backgroundImage && isValidImageData(backgroundImage) ? (
            <ImageVaultImage
              image={backgroundImage}
              layout="fill"
              sizes="50vw"
              priority
            />
          ) : null
        }
        backgroundOpacity={backgroundOpacity.value}
      >
        {features.map((feature) => (
          <ShortFeaturesUi.Feature
            key={feature._uid}
            text={feature.text}
            description={feature.description}
            showLabel={feature.showLabel}
            label={feature.label}
            labelPrefix={feature.labelPrefix}
            labelSuffix={feature.labelSuffix}
            inverted={IsFeatureInverted}
            style={featureStyle}
            boxed={featureBoxed}
            icon={feature.icon}
            iconColor={feature.iconColor}
            button={
              feature.buttonText &&
              feature.buttonLink && (
                <Button
                  href={resolveLink(feature.buttonLink)}
                  title={feature.buttonText}
                  size="small"
                  variant={buttonVariant}
                >
                  {feature.buttonText}
                </Button>
              )
            }
          />
        ))}
      </ShortFeaturesUi>
    </>
  );
};

export default ShortFeatures;
