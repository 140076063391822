import React, { useRef } from "react";
import cn from "classnames";
import s from "./Testimonials.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import { useTranslation } from "next-i18next";
import SliderButton from "../Common/SliderButton/SliderButton";
import Testimonial from "./components/Testimonial";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/a11y";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import ImageVaultImage from "../../components/ImageVaultImage";
import BlockIntro from "../Common/BlockIntro";
import Button from "../../ui/Button";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";

const Testimonials = ({ title, spacing, testimonials }) => {
  const { t } = useTranslation();
  const resolveLink = useLinks();
  const swiperRef = useRef<SwiperRef>(null);

  const slideNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <section className={cn(s.testimonials, bs[spacing])}>
      <BlockIntro
        alignment={"alignCenter"}
        inverted={false}
        title={title}
        description={""}
        buttons={""}
      />

      <div className={s.sliderSection}>
        <div className={cn(c.xxWide, s.slider)}>
          <Swiper
            modules={[EffectFade, Autoplay]}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
            speed={500}
            centeredSlides={false}
            spaceBetween={0}
            loop={true}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            mousewheel={false}
            slidesPerView={1}
            ref={swiperRef}
            className={s.swiperContainer}
          >
            {testimonials?.map((testimonial, index) => (
              <SwiperSlide key={index} className={s.slideItem}>
                <Testimonial
                  quote={testimonial.quote}
                  name={testimonial.name}
                  roleAndCompany={testimonial.roleAndCompany}
                  logo={
                    isValidImageData(testimonial.logo) ? (
                      <ImageVaultImage
                        image={testimonial.logo}
                        contain
                        sizes={imageSizes("320px")}
                      />
                    ) : null
                  }
                  buttons={testimonial.buttons?.map((button) => (
                    <Link
                      key={button._uid}
                      href={
                        button.buttonLink ? resolveLink(button.buttonLink) : ""
                      }
                      passHref
                    >
                      <Button
                        variant={button.buttonType || "default"}
                        href={resolveLink(button.buttonLink)}
                        size="small"
                      >
                        {button.buttonText}
                      </Button>
                    </Link>
                  ))}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {testimonials.length > 1 && (
          <div className={cn(c.xWide, s.navigation)}>
            <SliderButton
              onClick={slidePrev}
              align="prev"
              label={t("previous")}
              inverted={false}
            />
            <SliderButton
              onClick={slideNext}
              align="next"
              label={t("next")}
              inverted={false}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
