import React, { useCallback } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import PreambleUi from "../../ui/Preamble";
import { PreambleBlock } from "../../lib/storyblok/types/blocks/PreambleBlock";
import { useLinks } from "../../lib/storyblok/useLinks";
import Button from "../../ui/Button";
import Link from "next/link";

const Preamble: StoryblokBlockComponent<PreambleBlock> = ({
  title,
  text,
  titleType,
  noTopPadding,
  noBottomPadding,
  innerSpacing,
  contentAlignment,
  buttons,
}) => {
  const resolveLink = useLinks();

  const renderButtons = useCallback(
    () =>
      buttons?.map((button) => (
        <Link
          key={button._uid}
          href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
          passHref
        >
          <Button
            variant={button.buttonType || "default"}
            href={resolveLink(button.buttonLink)}
          >
            {button.buttonText}
          </Button>
        </Link>
      )),
    [buttons, resolveLink]
  );

  return (
    <PreambleUi
      title={title}
      labelAs={titleType || "h2"}
      text={text}
      noTopPadding={noTopPadding}
      noBottomPadding={noBottomPadding}
      innerSpacing={innerSpacing}
      contentAlignment={contentAlignment}
      buttons={renderButtons()}
    />
  );
};

export default Preamble;
